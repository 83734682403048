@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #575757;
  /* background: #f3f4f6; */
}

*::-webkit-scrollbar {
  display: none;
}

input::-ms-reveal {
  display: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}

.particle {
  position: absolute;
  top: -10px;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  opacity: 0.8;
  animation: float 10s linear infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

/* Music */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: spin 3s linear infinite; /* Adjust the speed with '3s' */
}